<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->
<style>
/* .treeview {
  float: left;
  width: 100%;
  background-color: rgb(255, 254, 253);
  padding: 15px 30px 30px;
} */

.treeview {
  float: left;
  margin-left: 1.2cm;
  margin-top: 0.5cm;
  width: 95.5%;
  position: relative;
}

.treeview .li {
  list-style: none;
  float: left;
  width: 100%;
  border-left: 1px solid #7367f0;
  padding: 10px 0;
}

.treeview .li .div {
  float: left;
  width: 90%;
  font-family: Arial;
  font-size: 15px;
  color: rgb(152, 64, 224);
  line-height: 1.5;
  padding-left: 33px;
  position: relative;
  bottom: -20px;
}

.treeview .li .div .img,
.img {
  /* width: 30px !important;
  height: 30px !important; */
  border: 2px solid #7367f0 !important;
  border-radius: 50% !important;
}

.treeview .li .div:before {
  content: "";
  width: 30px;
  height: 1px;
  background-color: #7367f0;
  position: absolute;
  top: 50%;
  bottom: 50%;
  left: 0;
}

@media only screen and (min-width: 600px) {
  .hoverview {
    position: absolute;
    width: 40%;
    height: 100px;
    margin-left: 53%;
    padding: 10px;
    box-shadow: 3px 4px 13px #4b4545ba;
  }

  .ifmobile {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
}

@media only screen and (max-width: 700px) {
  .hoverview {
    position: absolute;
    width: 85%;
    height: 100px;
    margin-left: -25px;
    padding: 10px;
    box-shadow: 3px 4px 13px #4b4545ba;
  }

  .ifmobile {
    display: none;
    /* clear: both;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
  display: flex;
  flex-direction: column;
  width: 100%; */
  }

  .ifmobile1 {
    display: flex;
    flex-direction: column;
  }
}

.sales {
  font-size: "x-small";
  color: black;
  width: 25%;
  font-weight: normal;
}

.visit {
  font-size: "x-small";
  color: black;
  width: 20%;
  font-weight: normal;
}

.imgbox {
  align-self: center !important;
  width: 20%;
}

.box {
  height: auto;
  border-radius: 15px;
  background-color: white;
  z-index: 100;
}

.name {
  width: 15%;
  align-self: center !important;
  color: black;
}

.icon {
  cursor: pointer;
}

.imgboxMain {
  align-self: center !important;
  width: 15%;
}

.cpbutton {
  align-self: center !important;
}
</style>
<template>
  <div>
    <b-tabs horizontal content-class=" mt-1 mt-md-0" pills nav-wrapper-class="div" nav-class="nav-left">
      <b-tab title="Tree" active>
        <b-card-code customClass="overflow-auto">
          <b-form-group style="margin-bottom: 10px" v-if="userData.role == 'admin'">
            <b-form-radio-group id="radio-group-3" v-model="type" name="radio-sub-component"
              @input="clickRadio($event)">
              <b-form-radio name="some-radios" value="channelpartner">ChannelPartner</b-form-radio>
              <b-form-radio name="some-radios1" value="franchise">Franchise</b-form-radio>
              <b-form-radio name="some-radios1" value="employee">Employee</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
          <div style="margin-bottom: -19px; padding-left: 2.2rem; font-weight: 500"
            class="d-flex justify-content-between overflow-auto">
            <div class="d-flex imgboxMain">
              <b-media-aside>
                <b-link @mouseenter="activeImageAdmin = true" @mouseleave="activeImageAdmin = false">
                  <b-avatar size="40" variant="light-primary" badge class="badge-minimal" badge-variant="success">
                    <b-img rounded class="img" :src="userData.profile_image && userData.profile_image !== null
            ? userData.profile_image
            : 'https://aircraft.wpenginepowered.com/wp-content/uploads/2016/09/no-profile-img.jpg'"
                      style="width: 100%; height: 100%" />
                    <!-- <b-img
                rounded
                class="img"
                :src="
                  userData.profile_image && userData.profile_image !== null
                    ? userData.profile_image
                    : 'https://aircraft.wpenginepowered.com/wp-content/uploads/2016/09/no-profile-img.jpg'
                "
                height="30"
              /> -->
                  </b-avatar>
                </b-link>
                <b-img v-if="activeImageAdmin" style="
                position: absolute;
                width: 120px;
                margin-left: 30px;
                height: 120px;
                z-index: 1;
              " thumbnail :src="userData.profile_image && userData.profile_image !== null
              ? userData.profile_image
              : 'https://aircraft.wpenginepowered.com/wp-content/uploads/2016/09/no-profile-img.jpg'
            " />
              </b-media-aside>
              <label>
                {{ userData.name }} &nbsp;&nbsp;{{
            userDownListLength == 0 ? "" : "(" + userDownListLength + ")"
          }}</label>
            </div>
            <b-row class="ifmobile">
              <b-col class="name ifmobile1">
                <div>
                  <span>Lead Count</span><span>:{{ newUser.leadcount ? newUser.leadcount : 0 }}</span>
                </div>
                <div>
                  <span>Insentive</span><span>:{{ newUser.userlevel ? newUser.userlevel : 0 }}%</span>
                </div>
              </b-col>

              <b-col class="sales ifmobile1" style="font-weight: 500">
                <div>
                  <span>Total Bussiness</span><span>:{{ newUser.totalbusiness ? newUser.totalbusiness : 0 }}</span>
                </div>
                <div>
                  <span>Total Payout</span><span>:{{ newUser.totalpayout ? newUser.totalpayout : 0 }}</span>
                </div>
                <div>
                  <span>Pending Payout</span><span>:{{ newUser.pendingpayout ? newUser.pendingpayout : 0 }}</span>
                </div>
              </b-col>
              <b-col class="visit ifmobile1" style="font-weight: 500">
                <div>
                  <span>Self Visit</span>
                  <span>:{{ newUser.selfvisit ? newUser.selfvisit : 0 }}</span>
                </div>
                <div>
                  <span>Team Visit</span><span>:{{ newUser.teamvisit ? newUser.teamvisit : 0 }}</span>
                </div>
                <div>
                  <span>Total Visit</span><span>:{{
            isNaN(newUser.teamvisit + newUser.selfvisit)
              ? 0
              : newUser.teamvisit + newUser.selfvisit
          }}</span>
                </div>
              </b-col>
            </b-row>

            <div class="cpbutton">
              <b-button variant="success" size="sm" class="ml-2" @click="handleClickAddCP">
                <feather-icon icon="PlusIcon" class="mr-50" />
                <span class="align-middle">Add ChannelPartner</span>
              </b-button>
            </div>
          </div>
          <ul class="treeview">
            <li v-for="(item, index) in userOption" :style="item.role == type || notAdmin ? '' : 'display:none'"
              class="pt-1 li" :key="index">
              <div class="div" :class="item.children !== null &&
              item.children
              ? 'bold d-flex justify-content-between align-items-center'
              : 'd-flex justify-content-between align-items-center'
            ">
                <b-media-aside @click="handelclickitem(item.id)" class="imgbox">
                  <b-link @mouseenter="hoverShowImage(item.id)" @mouseleave="leaveShowImage(item.id)">
                    <b-avatar size="40" variant="light-primary" badge class="badge-minimal"
                      :badge-variant="item.is_active ? 'success' : 'danger'"> <b-img rounded class="img" :src="item.profile_image && item.profile_image !== null
            ? item.profile_image
            : 'https://aircraft.wpenginepowered.com/wp-content/uploads/2016/09/no-profile-img.jpg'"
                        style="width: 100%; height: 100%" />
                      <!-- <b-img
                  class="img"
                  rounded
                  :src="
                    item.profile_image && item.profile_image !== null
                      ? item.profile_image
                      : 'https://aircraft.wpenginepowered.com/wp-content/uploads/2016/09/no-profile-img.jpg'
                  "
                  height="30"
                /> -->
                    </b-avatar>
                  </b-link>
                  <b-img v-if="activeImage[item.id]" style="
                  position: absolute;
                  width: 120px;
                  margin-left: 30px;
                  height: 120px;
                  z-index: 1;
                " thumbnail :src="item.profile_image && item.profile_image !== null
              ? item.profile_image
              : 'https://aircraft.wpenginepowered.com/wp-content/uploads/2016/09/no-profile-img.jpg'
            " />

                  <label class="pl-1" style="z-index: 0">
                    {{ item.name }}&nbsp;&nbsp;&nbsp;{{
            downList[item.id] == 0 ? "" : "(" + downList[item.id] + ")"
          }}
                    <div class="w-100" v-if="userData.role !== 'admin'">
                      <small> {{ item.role !== userData.role ? '(channelpartner)' : '' }} </small>
                    </div>
                  </label>

                </b-media-aside>
                <b-row class="ifmobile">
                  <b-col md="4" class="name">
                    <b-row><b-col md="7"><span>Lead Count</span></b-col><span>:{{ item.leadcount ? item.leadcount : 0
                        }}</span></b-row>
                    <b-row><b-col md="7"><span>Insentive</span></b-col><span>:{{ item.userlevel ? item.userlevel : 0
                        }}%</span></b-row>
                  </b-col>
                  <b-col md="4" class="sales">
                    <b-row><b-col md="7"><span>Total Bussiness</span></b-col><span>:{{ item.totalbusiness ?
            item.totalbusiness : 0 }}</span></b-row>
                    <b-row><b-col md="7"><span>Total Payout</span></b-col><span>:{{ item.totalpayout ? item.totalpayout
            : 0 }}</span></b-row>
                    <b-row><b-col md="7"><span>Pending Payout</span></b-col><span>:{{ item.pendingpayout ?
            item.pendingpayout : 0 }}</span></b-row>
                  </b-col>
                  <b-col md="3" class="visit">
                    <b-row><b-col md="7"><span>Self Visit</span></b-col><span>:{{ item.selfvisit ? item.selfvisit : 0
                        }}</span></b-row>
                    <b-row><b-col md="7"><span>Team Visit</span></b-col><span>:{{ item.teamvisit ? item.teamvisit : 0
                        }}</span></b-row>
                    <b-row><b-col md="7"><span>Total Visit</span></b-col><span>:{{
            isNaN(item.teamvisit + item.selfvisit)
              ? 0
              : item.teamvisit + item.selfvisit
          }}</span></b-row>
                  </b-col>
                </b-row>
                <feather-icon @click="showHoverview(index)" class="icon" width="20" height="20" icon="EyeIcon" />
                <div v-if="active[index]" class="hoverview d-flex flex-column box">
                  <b-row>
                    <b-col class="pl-2.3 d-flex justify-content-between" sm="12">
                      <b-media-aside>
                        <b-link>
                            <b-img rounded :src="item.profile_image
                                ? item.profile_image
                                : 'https://aircraft.wpenginepowered.com/wp-content/uploads/2016/09/no-profile-img.jpg'
                              " height="80" />
                        </b-link>
                      </b-media-aside>

                      <div class="d-flex flex-column justify-content-between">
                        <div @click="hideActive(index)" style="
                        align-self: end;
                        font-size: 0.788cm;
                        cursor: pointer;
                      ">
                          ×
                        </div>
                        <b-button style="height: 35px" @click="redirectSales(item.id)" size="sm" variant="primary">Add
                          Sales</b-button>
                      </div>
                    </b-col>
                    <b-col sm="12">
                      <label class="pl-1">Name&nbsp;: {{ item.name }}</label>
                    </b-col>
                    <b-col sm="12">
                      <label class="pl-1">Email&nbsp;: {{ item.email }}</label>
                    </b-col>
                    <b-col sm="12">
                      <label class="pl-1">Aadharcard No&nbsp;: {{ item.aadharcardno }}</label>
                    </b-col>
                    <b-col sm="12">
                      <label class="pl-1">Username&nbsp;: {{ item.username }}</label>
                    </b-col>
                    <b-col sm="12">
                      <label class="pl-1">Mobile No&nbsp;: {{ item.mobile }}</label>
                    </b-col>
                    <b-col class="d-flex justify-content-between" sm="12">
                      <label class="pl-1 d-flex justify-content-between align-items-center"
                        style="color: rgb(152 64 224); font-weight: bold">
                        Status : {{ item.is_active == 1 ? "Enable" : "Disable" }}
                      </label>
                      <b-button @click="toggleStatus(item.id, item.is_active)" :name="item.name" size="sm"
                        :variant="item.is_active == 1 ? 'danger' : 'success'">{{ item.is_active == 0 ? "Enable" :
                        "Disable" }}</b-button>
                    </b-col>
                  </b-row>
                </div>
              </div>
              <ul v-if="item.children && showItem[item.id]"
                :class="item.children && showItem[item.id] ? 'treeview' : ''">
                <tree :item="item" :getUserByParentId="getUserByParentId" />
              </ul>
            </li>
          </ul>
        </b-card-code>
      </b-tab>

      <b-tab title="Levels">
        <lavels/>
      </b-tab>
    </b-tabs>

  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
// import TreeChart from 'vue-tree-chart'
import axios from '@/components/axios';
import lavels from './Lavels'
import {
  BMediaAside,
  BLink,
  BImg,
  BButton,
  BRow,
  BCol,
  BFormRadio,
  BFormRadioGroup,
  BFormGroup,
  BAvatar,
  BTab,
  BTabs,
} from "bootstrap-vue";
import image from "../../../assets/images/pages/noProfileImage.png";
import tree from "./AddTree.vue";
export default {
  components: {
    lavels,
    BRow,
    BCol,
    tree,
    BButton,
    BCardCode,
    BMediaAside,
    BLink,
    BImg,
    BFormGroup,
    BFormRadio,
    BFormRadioGroup,
    BAvatar,
    BTab,
    BTabs,
  },
  data() {
    return {
      role: "",
      newUser: "",
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      demo: "",
      treeData: "",
      userData: "",
      userOption: [],
      activeImageAdmin: false,
      active: {},
      activeImage: {},
      showItem: {},
      image,
      downList: {},
      userDownListLength: "",
      type: "channelpartner",
      notAdmin: false
    };
  },
  mounted() {
    // const data = localStorage.getItem("userData");
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData.role !== "admin") {
      this.notAdmin = true
    }
    this.getUsers();
    this.role = userData.role;
    this.getUserByParentId();
  },
  methods: {
    clickRadio(e) {
      console.log(e);
    },
    leaveShowImage() {
      this.activeImage = {};
    },
    hoverShowImage(id) {
      if (this.activeImage[id] == null) {
        this.activeImage = {};
        this.activeImage[id] = false;
      }
      if (this.activeImage[id] == true) {
        this.activeImage = {};
      } else {
        this.activeImage[id] = this.activeImage[id] != true;
      }
      this.$forceUpdate();
    },
    redirectSales(id) {
      this.$router.push({ path: "/crm/sales/addsales", query: { userId: id } });
    },
    async getUsers() {
      this.userData = JSON.parse(localStorage.getItem("userData"));
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getusersbyid/${this.userData.id}`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.newUser = response.data.data[0];
        })
        .catch((error) => console.log(error, "error"));
    },
    async toggleStatus(id, isActive) {
      const data = {
        is_active: isActive == 1 ? 0 : 1,
      };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/isactive/${id}`,
        data: JSON.stringify(data),
      };
      await axios(requestOptions)
        .then((json) => {
          this.$swal({
            title: "Submited",
            text: json.data.message
              ? `${json.data.message}`
              : json.data.success
                ? `${json.data.success}`
                : "Update SuccessFully",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.getUserByParentId();
        })
        .catch((error) => console.log(error, "error"));
    },
    hideActive(index) {
      this.active[index] = false;

      this.$forceUpdate();
    },
    showHoverview(index) {
      if (this.active[index] == null) {
        this.active = {};
        this.active[index] = false;
      }
      if (this.active[index] == true) {
        this.active = {};
      } else {
        this.active[index] = this.active[index] != true;
      }
      this.$forceUpdate();
    },
    handleClickAddCP() {
      this.$router.push("/master/crm/addchannelpartner");
    },
    handelclickitem(id) {
      if (this.showItem[id] == null) {
        this.showItem[id] = 0;
      }
      this.showItem[id] = this.showItem[id] == 1 ? 0 : 1;
      this.$forceUpdate();
    },
    async getUserByParentId() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getUserByParentId/${this.userData.id}`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.userOption = response.data.data;
          this.downList = {};
          this.userDownListLength = this.userOption.length;
          this.userOption.map((item) => {
            item.children = item.children ? item.children : []
            item.assigned_user = item.assigned_user ? item.assigned_user : []
            this.downList[item.id] = item.children.length + item.assigned_user.length;

            this.userDownListLength =
              this.userDownListLength + item.children.length + item.assigned_user.length;
            if (item.children !== null || item.assigned_user !== null) {
              this.downListCalculation([...item.children, ...item.assigned_user], item.id);
            }
          });
        })
        .catch((error) => console.log(error, "error"));
    },
    downListCalculation(item1, id) {
      item1.map((item) => {
        item.children = item.children ? item.children : []
        item.assigned_user = item.assigned_user ? item.assigned_user : []

        this.downList[id] = this.downList[id] + item.children.length + item.assigned_user.length;
        this.userDownListLength =
          this.userDownListLength + item.children.length + item.assigned_user.length;
        this.downListCalculation1([...item.children, ...item.assigned_user], id);
      });
    },
    downListCalculation1(item1, id) {
      item1.map((item) => {
        item.children = item.children ? item.children : []
        item.assigned_user = item.assigned_user ? item.assigned_user : []
        this.downList[id] = this.downList[id] + item.children.length + item.assigned_user.length;

        this.userDownListLength =
          this.userDownListLength + item.children.length + item.assigned_user.length;

        if (item.children !== null || item.assigned_user !== null) {
          this.downListCalculation([...item.children, ...item.assigned_user], id);
        }
      });
    },
  },
};
</script>
