<template>
  <div>
    <b-card-code>
      <b-row class="mb-2 justify-content-between">
        <b-col md="4">
          <v-select
            v-model="user"
            label="fullname"
            placeholder="None"
            :options="userOptions"
            @input="handleUserChange"
          >
          <template #option="{ fullname, profile_image, username, color }">
                    <b-avatar  :src="getprofileImage(profile_image)" />
                    <span :class="'font-weight-bolder text-'+color "> {{ fullname }}  ({{ username }})</span
                    >
                  </template>
          </v-select>
        </b-col>
         <b-col md="4">
            <b-form-input class="Search" v-model="search" placeholder="Search" @input="debounceInput($event)" />
        </b-col>
        <b-col md="4" class="d-flex justify-content-end align-items-center">
          Total Team:
          <b-badge variant="success" class="ml-1">
            <span class="font-weight-bold">{{
              user ? all_levels.flat().length + 1 : 0
            }}</span>
          </b-badge>
        </b-col>
      </b-row>

      <b-tabs
        horizontal
        content-class=" mt-1 mt-md-0"
        nav-wrapper-class="div"
        nav-class="nav-left"
      >
        <b-tab
          active
          v-for="(item, index) in levels"
          :key="index"
          v-if="item && item.length > 0"
          @click="handleTabChange"
        >
          <template #title>
            <span class="font-weight-bold" v-if="index == levels.length - 1">Total</span>
            <span class="font-weight-bold" v-else>Lavel {{ index + 1 }}</span>
            <b-badge variant="light-success" class="ml-1">
              <span class="font-weight-bold">{{ item.length }}</span>
            </b-badge>
          </template>
          <div v-if="loading" style="height:100px" class="d-flex align-items-center justify-content-center">
            <b-spinner></b-spinner>
          </div>
          <div class="overflow-auto" v-else>
            <div class="mb-1 mt-1">
              <b-button variant="primary" size="sm" @click="handleDownload(item,index)">
                <feather-icon icon="DownloadIcon" />
                Download
              </b-button>
            </div>
            <table class="table table-bordered" style="min-width: 30cm">
              <thead>
                <tr>
                  <th>Sr.no</th>
                  <th>Image</th>
                  <th>Name</th>
                  <th style="width:5cm">Lead</th>
                  <th style="width:7cm">Business</th>
                  <th style="width:4cm">Site Visit</th>
                  <th>Parent</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(inner, inIndex) in item" :key="inIndex">
                  <td>{{ inIndex + 1 }}</td>
                  <td>
                    <b-media-aside>
                      <b-link>
                        <b-img
                          rounded
                          :src="
                            inner.profile_image
                              ? inner.profile_image
                              : 'https://aircraft.wpenginepowered.com/wp-content/uploads/2016/09/no-profile-img.jpg'
                          "
                          height="60"
                          width="60"
                          class="rounded-circle"
                        />
                      </b-link>
                    </b-media-aside>
                  </td>
                  <td>{{ inner.fullname }}</td>
                  <td>
                    <div>
                      <p>
                        Lead Count: {{ inner.leadcount ? inner.leadcount : 0 }}
                      </p>
                      <p>
                        Insentive: {{ inner.userlevel ? inner.userlevel : 0 }}%
                      </p>
                    </div>
                  </td>
                  <td>
                    <p>
                      Total Bussiness:
                      {{ inner.totalbusiness ? inner.totalbusiness : 0 }}
                    </p>
                    <p>
                      Total Payout:{{ inner.totalpayout ? inner.totalpayout : 0 }}
                    </p>
                    <p>
                      Pending Payout:
                      {{ inner.pendingpayout ? inner.pendingpayout : 0 }}
                    </p>
                  </td>
                  <td>
                    <p>Self Visit: {{ inner.selfvisit ? inner.selfvisit : 0 }}</p>
                    <p>Team Visit: {{ inner.teamvisit ? inner.teamvisit : 0 }}</p>
                    <p>
                      Total Visit:
                      {{
                        isNaN(inner.teamvisit + inner.selfvisit)
                          ? 0
                          : inner.teamvisit + inner.selfvisit
                      }}
                    </p>
                  </td>
                  <td>{{ inner.parent ? inner.parent.fullname : "" }}</td>
                  <td>
                    <feather-icon
                      @click="showHoverview(inIndex)"
                      width="20"
                      height="20"
                      icon="EyeIcon"
                      class="icon"
                    />
                    <div
                      v-if="active[inIndex]"
                      class="clickview d-flex flex-column box"
                    >
                      <b-row>
                        <b-col
                          class="pl-2.3 d-flex justify-content-between mb-1"
                          sm="12"
                        >
                          <b-media-aside>
                            <b-link>
                              <b-img
                                rounded
                                :src="
                                  inner.profile_image
                                    ? inner.profile_image
                                    : 'https://aircraft.wpenginepowered.com/wp-content/uploads/2016/09/no-profile-img.jpg'
                                "
                                height="80"
                              />
                            </b-link>
                          </b-media-aside>
                          <div
                            class="d-flex flex-column justify-content-between"
                          >
                            <div
                              @click="hideActive(inIndex)"
                              style="
                                align-self: end;
                                font-size: 0.788cm;
                                cursor: pointer;
                              "
                            >
                              ×
                            </div>
                            <b-button
                              style="height: 35px"
                              @click="redirectSales(inner.id)"
                              size="sm"
                              variant="primary"
                              >Add Sales</b-button
                            >
                          </div>
                        </b-col>
                        <b-col sm="12">
                          <label class="pl-1"
                            >Name&nbsp;: {{ inner.name }}</label
                          >
                        </b-col>
                        <b-col sm="12">
                          <label class="pl-1"
                            >Email&nbsp;: {{ inner.email }}</label
                          >
                        </b-col>
                        <b-col sm="12">
                          <label class="pl-1"
                            >Aadharcard No&nbsp;:
                            {{ inner.aadharcardno }}</label
                          >
                        </b-col>
                        <b-col sm="12">
                          <label class="pl-1"
                            >Username&nbsp;: {{ inner.username }}</label
                          >
                        </b-col>
                        <b-col sm="12">
                          <label class="pl-1"
                            >Mobile No&nbsp;: {{ inner.mobile }}</label
                          >
                        </b-col>
                        <b-col class="d-flex justify-content-between" sm="12">
                          <label
                            class="pl-1 d-flex justify-content-between align-items-center"
                            style="color: rgb(152 64 224); font-weight: bold"
                          >
                            Status :
                            {{ inner.is_active == 1 ? "Enable" : "Disable" }}
                          </label>
                          <b-button
                            @click="toggleStatus(inner.id, inner.is_active)"
                            :name="inner.name"
                            size="sm"
                            :variant="
                              inner.is_active == 1 ? 'danger' : 'success'
                            "
                            >{{
                              inner.is_active == 0 ? "Enable" : "Disable"
                            }}</b-button
                          >
                        </b-col>
                      </b-row>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>
      </b-tabs>
    </b-card-code>
  </div>
</template>
<script>
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code";

import {
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  VBTooltip,
  BTabs,
  BTab,
  BImg,
  BLink,
  BMediaAside,
  BBadge,
  BFormInput,
  BSpinner,
  BAvatar
} from "bootstrap-vue";

import axios from "axios";
export default {
  components: {
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    VBTooltip,
    vSelect,
    BCardCode,
    BTabs,
    BTab,
    BImg,
    BLink,
    BBadge,
    BMediaAside,
    BFormInput,
    BSpinner,
    BAvatar
  },
  data() {
    return {
      levels: [],
      user: "",
      level_value: 0,
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      userOptions: [],
      active: {},
      level_index: {},
      search:'',
      all_levels:[],
      loading:false,
      userData:{},
      allCp:[]
    };
  },
  mounted() {
    this.userData=this.$store.state.app.user_data;
    if(this.userData.role == 'channelpartner' || this.userData.role == 'franchise'){
      this.getAllChild();
    }
    else{
      this.getUsers()
    }
  },
  methods: {
    handleDownload(item,index) {
      var aoa = [
        ['Sr. No', 'Username', 'Name','Parent','Mobile','Email'],
        [],

      ];
      if (item && item.length > 0) {
        item.map((inner, index) => {
          aoa.push([index + 1, inner.username, inner.fullname,inner.parent?inner.parent.fullname:'',inner.mobile,inner.email,])
        })
      }

      var ws = XLSX.utils.aoa_to_sheet(aoa);
      var html_string = XLSX.utils.sheet_to_html(ws, { id: "data-table", editable: true });
      document.getElementById("container").innerHTML = html_string;
      function doit(type, fn) {
        var elt = document.getElementById('data-table');
        var wb = XLSX.utils.table_to_book(elt, { sheet: "Sheet JS" });
        XLSX.writeFile(wb, fn || ('SheetJSTableExport.' + (type || 'xlsx')));
      }
      let name = index == (this.levels.length-1) ? 'total' : (index+1) + " level "
      doit('xlsx', (this.user?this.user.fullname:'') + ' '+ name+ ' list.xlsx');
    },
    async getAllChild(){
      await axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getAllChilds`,
      }).then((res) => {
        this.userOptions = res.data.data;
      });
    },
    handleTabChange() {
      this.active = {};
    },
    debounceInput: _.debounce(function (str) {
      this.handleSearch(str)
    }, 1000),
    handleSearch(e) {
      this.levels=[]
      if (e) {
        this.all_levels.map((item,index) => {
          this.levels.push([]);
          item.map((inner)=>{
            if (inner?.fullname.toUpperCase().includes(e.toUpperCase())) {
              this.levels[index].push(inner)
            }
          })
        })
      }
      else {
        this.levels =[...this.all_levels]
      }
    },
    async toggleStatus(id, isActive) {
      const data = {
        is_active: isActive == 1 ? 0 : 1,
      };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/isactive/${id}`,
        data: JSON.stringify(data),
      };
      await axios(requestOptions)
        .then((json) => {
          this.$swal({
            title: "Submited",
            text: json.data.message
              ? `${json.data.message}`
              : json.data.success
              ? `${json.data.success}`
              : "Update SuccessFully",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.handleUserChange({ id: id });
        })
        .catch((error) => console.log(error, "error"));
    },
    redirectSales(id) {
      this.$router.push({ path: "/crm/sales/addsales", query: { userId: id } });
    },
    hideActive(index) {
      this.active[index] = false;

      this.$forceUpdate();
    },
    showHoverview(index) {
      if (this.active[index] == null) {
        this.active = {};
        this.active[index] = false;
      }
      if (this.active[index] == true) {
        this.active = {};
      } else {
        this.active[index] = this.active[index] != true;
      }
      this.$forceUpdate();
    },
    async getUsers() {
      await axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getWorkingUser`,
      }).then((res) => {
        this.userOptions = res.data;
      });
    },
    async handleUserChange(e) {
      this.all_levels = [];
      this.levels = [];
      this.search=''
      this.level_value = 0;
      if (e) {
        this.loading=true
        await axios({
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          url: `${this.baseApi}/getAllChildsLevelWise/${e.id}`,
        }).then((res) => {
          this.all_levels[this.level_value] = res.data.data;
          let value = 1;
          res.data.data.map((item) => {
            if (item.children && item.children.length > 0) {
              this.handleChild(item, value);
            }
          });
          this.allUsers=[e,...this.all_levels.flat()];
          this.all_levels.push(this.allUsers)
          this.levels =[...this.all_levels]
          this.loading=false

          this.$forceUpdate();
        })
        .catch(()=>{
          this.loading=false
        })
      }
    },
    handleChild(item, level_index) {
      let value = level_index;
      this.all_levels[value] = this.all_levels[value] ? this.all_levels[value] : [];
      this.all_levels[value] = [...this.all_levels[value], ...item.children];
      value++;
      item.children.map((inner) => {
        this.handleChild(inner, value);
      });
    },
  },
};
</script>
<style scopped>
@media only screen and (min-width: 600px) {
  .clickview {
    position: absolute;
    width: 40%;
    height: auto;
    right: 100px;
    padding: 10px;
    box-shadow: 3px 4px 13px #4b4545ba;
  }

  .ifmobileLevel {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
}

@media only screen and (max-width: 700px) {
  .clickview {
    position: absolute;
    width: 85%;
    height: auto;
    margin-left: -25px;
    padding: 10px;
    box-shadow: 3px 4px 13px #4b4545ba;
  }

  .ifmobileLevel {
    display: none;
    /* clear: both;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
  display: flex;
  flex-direction: column;
  width: 100%; */
  }

  .ifmobileLevel1 {
    display: flex;
    flex-direction: column;
  }
}
</style>
