<template>
  <div>
    <li v-for="(item1, index) in [...item.children,...item.assigned_user]" class="li" :key="index">
      <div
        class="div"
        :class="
          JSON.stringify(item.children) !== '[]' &&
          item1.children !== null &&
          item1.children
            ? 'bold d-flex justify-content-between align-items-center'
            : 'd-flex justify-content-between align-items-center'
        "
      >
        <b-media-aside class="imgbox" @click="handelclickchild(item1.id)">
          <b-link
            @mouseenter="hoverShowImage(item1.id)"
            @mouseleave="leaveShowImage(item1.id)"
          >
             <b-avatar
                size="40"
                variant="light-primary"
                badge
                class="badge-minimal"
                :badge-variant="item1.is_active ? 'success' : 'danger'"
              >
              <b-img
                  rounded
                  class="img"
                  :src="item1.profile_image && item1.profile_image !== null
                            ? item1.profile_image
                            : 'https://aircraft.wpenginepowered.com/wp-content/uploads/2016/09/no-profile-img.jpg'"
                  style="width: 100%; height: 100%"
                />
            <!-- <b-img
              class="img"
              rounded
              :src="
                item.profile_image && item.profile_image !== null
                  ? item.profile_image
                  : 'https://aircraft.wpenginepowered.com/wp-content/uploads/2016/09/no-profile-img.jpg'
              "
              height="30"
            /> -->
            </b-avatar>
          </b-link>
          <b-img
            v-if="activeImage[item1.id]"
            style="
              position: absolute;
              width: 120px;
              margin-left: 30px;
              height: 120px;
              z-index: 1;
            "
            thumbnail
            :src="
              item1.profile_image && item1.profile_image !== null
                ? item1.profile_image
                : 'https://aircraft.wpenginepowered.com/wp-content/uploads/2016/09/no-profile-img.jpg'
            "
          />
          <label class="pl-1">
            {{ item1.name }} &nbsp;&nbsp;&nbsp;{{
              downListChildren[item1.id] == 0
                ? ""
                : downListChildren[item1.id] == undefined
                ? ""
                : "(" + downListChildren[item1.id] + ")"
            }}
            <div class="w-100">
              <small> {{ item1.role !== item.role ? '(channelpartner)' : '' }} </small>
            </div>
            </label>
        </b-media-aside>
        <b-row class="ifmobile">
          <b-col md="4" class="name">
            <b-row
              ><b-col md="8"><span>Lead Count</span></b-col
              ><span>:{{ item1.leadcount ? item1.leadcount : 0 }}</span></b-row
            >
            <b-row
              ><b-col md="8"><span>Insentive</span></b-col
              ><span>:{{ item1.userlevel ? item1.userlevel : 0 }}%</span></b-row
            >
          </b-col>
          <b-col md="4" class="sales">
            <b-row
              ><b-col md="7"><span>Total Bussiness</span></b-col
              ><span
                >:{{ item1.totalbusiness ? item1.totalbusiness : 0 }}</span
              ></b-row
            >
            <b-row
              ><b-col md="7"><span>Total Payout</span></b-col
              ><span
                >:{{ item1.totalpayout ? item1.totalpayout : 0 }}</span
              ></b-row
            >
            <b-row
              ><b-col md="7"><span>Pending Payout</span></b-col
              ><span
                >:{{ item1.pendingpayout ? item1.pendingpayout : 0 }}</span
              ></b-row
            >
          </b-col>
          <b-col md="3" class="visit">
            <b-row
              ><b-col md="6"><span>Self Visit</span></b-col
              ><span>:{{ item1.selfvisit ? item1.selfvisit : 0 }}</span></b-row
            >
            <b-row
              ><b-col md="6"><span>Team Visit</span></b-col
              ><span>:{{ item1.teamvisit ? item1.teamvisit : 0 }}</span></b-row
            >
            <b-row
              ><b-col md="6"><span>Total Visit</span></b-col
              ><span>:{{ item1.teamvisit + item1.selfvisit }}</span></b-row
            >
          </b-col>
        </b-row>
        <feather-icon
          @click="showHoverview(index)"
          width="20"
          height="20"
          icon="EyeIcon"
          class="icon"
        />
        <div v-if="active[index]" class="hoverview d-flex flex-column box">
          <b-row>
            <b-col class="pl-2.3 d-flex justify-content-between" sm="12">
              <b-media-aside>
                <b-link>
                  <b-img
                    rounded
                    :src="
                      item1.profile_image
                        ? item1.profile_image
                        : 'https://aircraft.wpenginepowered.com/wp-content/uploads/2016/09/no-profile-img.jpg'
                    "
                    height="80"
                  />
                </b-link>
              </b-media-aside>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                "
              >
                <div
                  @click="hideActive(index)"
                  style="align-self: end; font-size: 0.788cm; cursor: pointer"
                >
                  ×
                </div>
                <b-button
                  style="height: 35px"
                  @click="redirectSales(item1.id)"
                  size="sm"
                  variant="primary"
                  >Add Sales</b-button
                >
              </div>
            </b-col>
            <b-col sm="12">
              <label class="pl-1">Name&nbsp;: {{ item1.name }}</label>
            </b-col>
            <b-col sm="12">
              <label class="pl-1">Email&nbsp;: {{ item1.email }}</label>
            </b-col>
            <b-col sm="12">
              <label class="pl-1"
                >Aadharcard No&nbsp;: {{ item1.aadharcardno }}</label
              >
            </b-col>
            <b-col sm="12">
              <label class="pl-1">Username&nbsp;: {{ item1.username }}</label>
            </b-col>
            <b-col sm="12">
              <label class="pl-1">Mobile No&nbsp;: {{ item1.mobile }}</label>
            </b-col>
            <b-col class="d-flex justify-content-between" sm="12">
              <label
                class="pl-1 d-flex justify-content-between align-items-center"
                style="color: rgb(152 64 224); font-weight: bold"
              >
                Status : {{ item1.is_active == 1 ? "Enable" : "Disable" }}
              </label>
              <b-button
                @click="toggleStatus(item1.id, item1.is_active)"
                :name="item1.name"
                size="sm"
                :variant="item1.is_active == 1 ? 'danger' : 'success'"
                >{{ item1.is_active == 0 ? "Enable" : "Disable" }}</b-button
              >
            </b-col>
          </b-row>
        </div>
      </div>
      <ul
        :class="item1.children && showChildren[item1.id] ? 'treeview' : ''"
        v-if="item1.children && showChildren[item1.id]"
      >
        <children :item="item1" :getUserByParentId="getUserByParentId" />
      </ul>
    </li>
    <!-- <div style="margin-top: 30px" v-for="(children, index) in item.children" :key="index">
      <div
        :class="JSON.stringify(children.children) !== '[]' && children.children !== null && children.children ? 'bold d-flex' : 'd-flex'"
        style="margin-left: 25px" @click="handelclickchild(children.id)">
        <b-media-aside>
          <b-link>
            <b-img rounded
              :src="children.profile_image && children.profile_image !== null ? children.profile_image : image"
              height="30" />
          </b-link>
        </b-media-aside>
        <label style="font-size: 20px">
          {{ children.name }}</label>{{ children.children }}
        <label style="font-size: 21px;padding-left: 10px">{{
    JSON.stringify(children.children) !== '[]' && children.children !== null && children.children
      ? showChildren[children.id]
        ? "&minus;"
        : "&plus;"
      : ""
}}</label>
      </div>
      <div style="margin-left: 25px" v-if="children.children && showChildren[children.id]">
        <children :item="children" />
      </div>
    </div> -->
  </div>
</template>
<script>
// import treee from "@js/components/tree/treee.vue";
import { BMediaAside, BLink,BAvatar, BImg, BButton, BRow, BCol } from "bootstrap-vue";
import image from "../../../assets/images/pages/noProfileImage.png";
import axios from '@/components/axios';

export default {
  name: "children",
  components: {
    BRow,
    BCol,
    BButton,
    children: this,
    BMediaAside,
    BLink,BAvatar,
    BImg,
  },
  props: ["item", "getUserByParentId"],
  data() {
    return {
      activeImage: {},
      role: "",
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      showChildren: {},
      active: {},
      image,
      downListChildren: {},
    };
  },
  mounted() {
    const data = localStorage.getItem("userData");
    const userData = JSON.parse(localStorage.getItem("userData"));
    this.role = userData.role;
    this.downListCalculationStart();
  },
  methods: {
    leaveShowImage() {
      this.activeImage = {};
    },
    hoverShowImage(id) {
      if (this.activeImage[id] == null) {
        this.activeImage = {};
        this.activeImage[id] = false;
      }
      if (this.activeImage[id] == true) {
        this.activeImage = {};
      } else {
        this.activeImage[id] = this.activeImage[id] != true;
      }
      this.$forceUpdate();
    },
    redirectSales(id) {
      this.$router.push({ path: "/crm/sales/addsales", query: { userId: id } });
    },
    async toggleStatus(id, isActive) {
      const data = {
        is_active: isActive == 1 ? 0 : 1,
      };
      this.toggle = isActive == 1 ? 0 : 1;
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/isactive/${id}`,
        data: JSON.stringify(data),
      };
      await axios(requestOptions)
        .then((json) => {
          this.$swal({
            title: "Submited",
            text: json.data.message
              ? `${json.data.message}`
              : json.data.success
              ? `${json.data.success}`
              : "Update SuccessFully",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.getUserByParentId();
        })
        .catch((error) => console.log(error, "error"));
    },
    hideActive(index) {
      this.active[index] = false;

      this.$forceUpdate();
    },
    showHoverview(index) {
      if (this.active[index] == null) {
        this.active = {};
        this.active[index] = false;
      }
      if (this.active[index] == true) {
        this.active = {};
      } else {
        this.active[index] = this.active[index] != true;
      }
      this.$forceUpdate();
    },
    handelclickchild(id) {
      if (this.showChildren[id] == null) {
        this.showChildren[id] = 0;
      }
      this.showChildren[id] = this.showChildren[id] == 1 ? 0 : 1;
      this.$forceUpdate();
    },
    downListCalculationStart() {
      this.item.children.map((item) => {
        this.downListChildren[item.id] = item.children.length;
        if (JSON.stringify(item.children) !== "[]" && item.children !== null) {
          this.downListCalculation(item.children, item.id);
        }
      });
    },
    downListCalculation(item1, id) {
      item1.map((item) => {
        if (JSON.stringify(item.children) !== "[]" && item.children !== null) {
          this.downListChildren[id] =
            this.downListChildren[id] + item.children.length;
          this.downListCalculation1(item.children, id);
        }
      });
    },
    downListCalculation1(item1, id) {
      item1.map((item) => {
        this.downListChildren[id] =
          this.downListChildren[id] + item.children.length;
        if (JSON.stringify(item.children) !== "[]" && item.children !== null) {
          this.downListCalculation(item.children, id);
        }
      });
    },
  },
};
</script>
